@import '../../scss/imports';

.chart-card {
  margin-top: .25rem;

  &.loading {
    display: grid;
    grid-row-gap: .25rem;
    grid-template-rows: 2.125rem repeat(10, 2rem);

    .loading-header,
    .loading-list {
      @include loading-styles();
      border-radius: .3125rem;
      height: 100%;
    }
  }
}
