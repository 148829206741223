@import '../../scss/imports';

.action-bar-container {
  align-items: center;
  display: flex;
  height: 148px;
  justify-content: space-between;

  h2 {
    color: #9e9e9e;
  }

  .search-input-container {
    position: relative;
    
    input {
      border: 1px solid #e0e0e0;
      border-radius: 1rem;
      font-family: $font-regular;
      font-size: .875rem;
      height: 2rem;
      line-height: 2rem;
      min-width: 100%;
      padding-left: 2.625rem;
    }
  
    span {
      height: 1.5rem;
      left: .5rem;
      position: absolute;
      top: .25rem;
      width: 1.5rem;
    }
  }
}
