@use '../scss/imports' as *;
@import '../scss/variables';

.tab-navigation {
  align-items: center;
  background-color: $white;
  border-top: .125rem solid #e7e7e7;
  display: flex;
  height: 3.375rem;
  justify-content: flex-start;
  padding: 0 1.375rem 0 2rem;
  position: relative;

  .wrapper {
    margin: 0 auto;
    width: $wrapper;

    .nav-link {
      color: #a8a8a8;
      cursor: pointer;
      display: inline-block;
      font-family: $font-demi;
      font-size: 1rem;
      font-weight: normal;
      height: 3.25rem;
      padding: 1rem 1.25rem;
      position: relative;
      text-decoration: none;
      transition: color 200ms ease-in-out;

      @include min-screen(810px) {
        font-size: 1.125rem;
      }

      @include min-screen($md) {
        font-size: 1.25rem;
      }

      &:last-child {
        margin-right: 0;
      }

      .bottom-border {
        background-color: $blue;
        bottom: 0;
        display: inline-block;
        height: .125rem;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition: width 200ms ease-in-out;
        width: 0;
      }


      &.active {
        color: $blue;

        .bottom-border {
          width: 100%;
        }
      }

      &:hover {
        color: #7ca6d4;
      }
    }

    .link-container {
      position: relative;

      @include min-screen(1000px) {
        &::after {
          background-color: $white;
          bottom: 0;
          content: '';
          display: inline-block;
          height: .125rem;
          left: 0;
          position: absolute;
          transition: left 200ms ease-in-out, width 200ms ease-in-out;
          width: 33%;
        }

        &.active-0 {
          &::after {
            left: 0;
            width: 16.75rem;
          }
        }

        &.active-1 {
          &::after {
            left: 43.6%;
            width: 12.875rem;
          }
        }

        &.active-2 {
          &::after {
            left: 77.1%;
            width: 8.75rem;
          }
        }
      }
    }
  }

  .subheader-action-button {
    --rmd-icon-color: #{rgba(#d8d8d8, .6)};
    padding: 0;

    &.toggle-active {
      --rmd-icon-color: #fff;
    }
  }

  .search-filter-container {
    display: inline-block;
    opacity: 0;
    position: absolute;
    right: .5rem;
    top: 3.5rem;
    transform: scale(0, 0);
    transition: transform 200ms ease-in-out;

    &.active {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}
