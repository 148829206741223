// APP COLORS
$black: #000;
$white: #fff;
$blue: #50a3ff;
$primary-color: #8036e7;
$secondary-color: $blue;
$tertiary-color: #919191;
$tertiary-text-color: $black;
$priority-color: #f11a1a;
$warning-color: #f44336;
$warning-text-color: $white;
$disabled-color: #c3c3c3;
$text-color: $black;
$text-color-light: rgba($black, .6);
$secondary-text-color: #989898;

// TEXTURES
$box-shadow: 0 .25rem 16px rgba(#000, .1);
$border-radius: .375rem;
$body-bg-color: #f4f4f4;

// FONTS
$ph-font-path: 'assets/fonts';
$font-ultra-light: 'AvenirNextLTW01-UltraLi', Arial, sans-serif;
$font-light: 'Avenir Next W01 Light', Arial, sans-serif;
$font-regular: 'AvenirNextLTW01-Regular', Arial, sans-serif;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial, sans-serif;
$font-demi: 'Avenir Next LT W01 Demi', Arial, sans-serif;
$font-heavy: 'AvenirNextLTW01-Heavy', Arial, sans-serif;

$custom-typography-styles: (
  letter-spacing: normal,
);

// SCREEN SIZES
$xxs: em(400);
$xs: em(480);
$sm: em(600);
$smt: em(800);
$md: em(960);
$lg: em(1280);
$xl: em(1440);
$ipad: em(768);
$landscape: em(1024);

$wrapper: 63.75rem;

// PH OVERRIDES
$ph-header-action-button-color: #a8a8a8;
$ph-header-background-color: #fff;
