* {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

html,
body {
  color: $text-color;
  font-family: $font-regular;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  height: auto;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
html {
  font-family: $font-regular;
  font-weight: normal;
}

hr {
  border: 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

button,
input {
  appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &[disabled] {
    opacity: .8;
  }
}

main {
  padding-bottom: 6.25rem;
}

.select-input {
  appearance: none;
  background-color: rgba(#000, .05);
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 7.33366L0.333496 0.666992H13.6668L7.00016 7.33366Z' fill='%23666666'/%3E%3C/svg%3E");
  background-position: right .625rem bottom .75rem;
  background-repeat: no-repeat;
  background-size: .75rem auto;
  border: 0;
  border-radius: $border-radius;
  cursor: pointer;
  padding: .5rem;
  width: 60%;

  &::-ms-expand {
    display: none;
  }

  &:disabled,
  &[aria-disabled=true] {
    opacity: .5;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: $wrapper;
  padding: 0;
  position: relative;
}

.center-container {
  align-content: center;
  display: grid;
  justify-content: center;
  min-height: 100vh;

  p {
    font-size: 1.5rem;
  }
}

.columns-container {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-areas: 'sidebar content';
  grid-template-columns: 232px auto;
}

.sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: content;
}

.column {
  display: inline-block;
}

.center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.ph-header-container {
  border-bottom: 0;
  box-shadow: $box-shadow;
}
