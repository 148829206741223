@import '../../scss/imports';

.permissions-container {
  ul {
    align-items: center;
    background: $white;
    border-radius: .25rem;
    display: flex;
    font-size: .875rem;
    height: 2.125rem;
    list-style: none;
    margin: .25rem;
    padding: 0;
    width: 100%;

    .group-name,
    .group-users_in_group,
    .group-app_permissions {
      overflow: hidden;
      padding-right: 1rem;
      text-overflow: ellipsis;
      text-transform: capitalize;
      white-space: nowrap;
    }

    .group-name {
      padding-left: .75rem;
    }

    .group-name,
    .group-users_in_group,
    .group-app_permissions {
      max-width: calc(33% - .875rem);
      min-width: calc(33% - .875rem);
    }

    .group-action {
      padding: .1875rem 1.125rem 0;
      text-align: right;
      width: 3rem;
    }
  }

  .groups-list-headers {
    align-items: center;
    display: flex;
    font-family: $font-demi;
    height: 2.75rem;
    margin: 0 .25rem;
    width: 100%;

    .groups-header-name {
      padding: 0 1rem 0 .75rem;
    }

    .groups-header-name,
    .groups-header-users_in_group,
    .groups-header-app_permissions {
      max-width: calc(33% - .875rem);
      min-width: calc(33% - .875rem);
    }

    .groups-header-name,
    .groups-header-users_in_group,
    .groups-header-app_permissions {
      span {
        margin-right: .375rem;
      }

      button {
        align-items: center;
        display: flex;
      }
    }

    .groups-header-action {
      opacity: .5;
      padding-right: .625rem;
      text-align: right;
      width: 3rem;
    }
  }
}
