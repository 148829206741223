@import '../../scss/variables';

.persons-container {
  ul {
    align-items: center;
    background: $white;
    border-radius: .25rem;
    display: flex;
    font-size: .875rem;
    height: 2.125rem;
    list-style: none;
    margin: .25rem;
    padding: 0;
    width: 100%;

    .user-checkbox {
      width: 2.75rem;
    }

    .user-display-name,
    .user-email,
    .user-permission-group,
    .user-status {
      overflow: hidden;
      padding-right: 1rem;
      text-overflow: ellipsis;
      text-transform: capitalize;
      white-space: nowrap;
    }

    .user-display-name {
      padding-left: .5rem;
    }

    .user-display-name,
    .user-email {
      max-width: calc(28% - 1.4375rem);
      min-width: calc(28% - 1.4375rem);
    }

    .user-permission-group,
    .user-status {
      max-width: calc(22% - 1.4375rem);
      min-width: calc(22% - 1.4375rem);
    }

    .user-action {
      padding: .1875rem 1.125rem 0;
      text-align: right;
      width: 3rem;
    }

    &.deactivated {
      opacity: .5;
    }
  }

  .user-list-headers {
    align-items: center;
    display: flex;
    font-family: $font-demi;
    height: 2.75rem;
    margin: 0 .25rem;
    width: 100%;

    .users-header-select-all {
      width: 2.75rem;
    }

    .users-header-name {
      padding: 0 1rem 0 .5rem;
    }

    .users-header-name,
    .users-header-email {
      max-width: calc(28% - 1.4375rem);
      min-width: calc(28% - 1.4375rem);
    }

    .users-header-user_group,
    .users-header-status {
      max-width: calc(22% - 1.4375rem);
      min-width: calc(22% - 1.4375rem);
    }

    .users-header-name,
    .users-header-email,
    .users-header-user_group,
    .users-header-status {
      span {
        margin-right: .375rem;
      }

      button {
        align-items: center;
        display: flex;
      }
    }

    .user-header-action {
      padding-right: .625rem;
      text-align: right;
      width: 3rem;
    }
  }
}
